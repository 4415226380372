import {Inject, Component, OnInit} from '@angular/core';
import {BusinessLogicRequirements, BusinessRequirementsInjectionToken} from '../business-logic.requirements';
import {SharedDataService} from '../../../lib/shared_data.service';
import {SharedService} from '../../../lib/shared-service';
import {AuthService} from '../../../lib/auth.service';
import {Router} from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { LocaleService } from 'src/app/lib/locale.service';

@Component({
    selector: 'app-live-classes-home',
    templateUrl: './live-classes-home-controller.component.html',
    styleUrls: ['./live-classes-home-controller.component.css']
})
export class LiveClassesHomeControllerComponent implements OnInit {
    public liveClassList;
    public userInfoSubscription;
    public isloading = true;
    public userData;
    public lesson;
    public notiPoup = false;
    public errorPoup = false;
    public joinPopup = {enable: false, title: null, classname: null, teachername: null, username: null, nodmaUrl: null, zoomLink: '', zoomRoomID: '', zoomRoomPasscode: ''};
    public isApp:boolean;
    public mobileOS;
    public isAcademyPermitted:boolean = true;
    public locale:any;
    public teacherList:any;
    public isProfileLoading:boolean = true;
    public isAndroidApp:boolean;

    constructor(
        private _sharedDataService: SharedDataService,
        private _sharedService: SharedService,
        private _authService: AuthService,
        private router: Router,
        private toastr: ToastrService,
        private _localeService: LocaleService,
        @Inject(BusinessRequirementsInjectionToken) private business: BusinessLogicRequirements
    ) {
        this.userInfoSubscription = this._sharedService.changeUserInfo$.subscribe(
          data => {
            this.checkLoginData();
        });
    }

    ngOnInit() {
      this.checkUserAgent();
      // this.loadData();
      this.getUserPermittedApps();
      this.getJson();
    }
    getJson(){
      this._localeService.getJson().subscribe(data => {
        console.log("searchResult###############",data)
        this.locale = data;
      })
    }
    checkUserAgent(){
      this._sharedService.isApp.subscribe(data => {
        this.isApp = data['isMobileApp'];
        this.mobileOS = data['mobileOS'].toLowerCase();
        this.isAndroidApp = this.isApp && this.mobileOS == 'android' ? true : false;
        console.log("checkUserAgent~~~",this.isApp)
      })
    }
    getUserPermittedApps(){
      let isAlreadyLogin = this._authService.isLoginUser();
      if(isAlreadyLogin){
        this._sharedDataService.getUserPermittedApps().subscribe((data:any) => {
          console.log("liveclass > getUserPermittedApps > data",data);
          this._sharedService.defineUserPermittedApps(data);
          let permittedApps = data.permittedApps.map(item => item.toLowerCase());
          this.isAcademyPermitted = permittedApps.indexOf('academy') == -1 ? false: true;
          if(this.isAcademyPermitted){
            setTimeout(()=>{
              this.loadData();
            },500)
          }
          this.getJson();
        })
      }else{
        this._sharedService.setEnableLogin({enable: true, url: this.router.url});
      }
    }
    loadData(){
      var alreadyLoggedin = this._authService.isLoginUser();
        console.log("this.liveClassList--->>",this.liveClassList)
        if(alreadyLoggedin) {
          this.getTeacherList();
          this.getAllClasses();
          this.userData = this._authService.getUserInfo();
          if(this.userData.usertype == undefined || this.userData.orgname == undefined || this.userData.permittedApps == undefined){
            console.log("old user > go to login");
            this.business.removeLocalStorage("userInfo");
            this.checkLoginData();
          }
          this.joinPopup.nodmaUrl = this._authService.getRedirectReaderUrl();
        }
        else this.checkLoginData();
    }
    getAllClasses() {
        this.business.getLiveClasses().subscribe(res=> {
            console.log('get live Classes', res)
            this.isloading = false;
            this.liveClassList = res;
            // this.checkClassCondition(this.liveClassList[0])
            this.liveClassList.forEach(classData => {
              classData = this.checkClassCondition(classData);
            });
        })
    }

    getTeacherList(){
      this.isProfileLoading = true;
      this.business.getTeacherList().subscribe(res => {
        console.log("getTeacherList",res);
        this.isProfileLoading = false;
        this.teacherList = res;
      })
    }

    checkCurrentTime( date, scope ) {
        var datetime = new Date( date ).getTime();
        var now = new Date().getTime();
        var result = false;

        if (datetime > now) {
            result = scope=='start'? false :  true;
        }
        else result = scope=='start'? true : false;

        return result;
    }

    checkClassCondition(classObj) {
      var day = new Date(classObj.startDate).getDay();
      switch(day){
        case 0 : classObj.background = '#CCC1A5'
          break;
        case 1 : classObj.background = '#79E1FA'
          break;
        case 2 : classObj.background = '#FFD918'
          break;
        case 3 : classObj.background = '#52CC85'
          break;
        case 4 : classObj.background = '#D3B3FD'
          break;
        case 5 : classObj.background = '#FEB3DF'
          break;
        case 6 : classObj.background = '#FEC985'
          break;
      }
      var start = this.checkCurrentTime(classObj.startDate, 'start');
      var end = this.checkCurrentTime(classObj.endDate, 'end');
      if(!end) {
        console.log('----> button is in class ended condition');
        classObj.button = {text: this.locale.liveclasses.classEnded, disabled: true, class: 'button-primary', loading: false}
        classObj.note = null;
        classObj.deviceMessageText = null;
      }
      else if(classObj.isEnrolled) {
        if(start && end ) {
          console.log('----> button is in join now condition');
          classObj.button = {text: this.locale.liveclasses.joinNow, disabled: this.userData.usertype != 5 || this.userData.usertype != '5' ? false : true, class: 'button-secondary', loading: false}
          classObj.note = null;
          classObj.deviceMessageText = this.mobileOS != 'unknown' && classObj.zoomLink == '' ? this.locale.liveclasses.deviceMessage : null;
        }
        else {
          console.log('----> button is in enrolled condition');
          classObj.button = {text: this.locale.liveclasses.enrolledButton, disabled: false, class: 'button-tertiary', loading: false}
          classObj.note = null;
          classObj.deviceMessageText = null;
        }
        this.realTimeChecker(classObj);
      }
      else {
        var classSizePercentage = (classObj.seatTaken/classObj.totalSeat)*100;
        if(classSizePercentage==100){
          console.log('----> Fully booked');
          classObj.button = {text: this.locale.liveclasses.fullyBookedButton, disabled: true, class: 'button-primary', loading: false}
          classObj.note = null;
          classObj.deviceMessageText = null;
        }
        else if(classSizePercentage>=50) {
          console.log('enroll----> Limited seats left!');
          classObj.button = {text: this.locale.liveclasses.enrollButton, disabled: this.userData.usertype != 5 || this.userData.usertype != '5' ? false : true, class: 'button-primary', loading: false}
          classObj.note = this.locale.liveclasses.limitedSeatsLeft;
          classObj.deviceMessageText = null;
        }
        else {
          console.log('enroll----> Save your spot!');
          classObj.button = {text: this.locale.liveclasses.enrollButton, disabled: this.userData.usertype != 5 || this.userData.usertype != '5' ? false : true, class: 'button-primary', loading: false}
          classObj.note = this.locale.liveclasses.saveSpot;
          classObj.deviceMessageText = null;
        }
      }
      return classObj;
    }

    realTimeChecker(data) {
      var start = this.checkCurrentTime(data.startDate, 'start');
      var end = this.checkCurrentTime(data.endDate, 'end');
      var timeout;
      if(!end) {
        data.button = {text: this.locale.liveclasses.classEnded, disabled: true, class: 'button-primary', loading: false}
        data.note = null;
        data.deviceMessageText = null;
      }
      else if(start && end ) {
        data.button = {text: this.locale.liveclasses.joinNow, disabled: this.userData.usertype != 5 || this.userData.usertype != '5' ? false : true, class: 'button-secondary', loading: false}
        data.note = null;
        data.deviceMessageText = this.mobileOS != 'unknown' && data.zoomLink == '' ? this.locale.liveclasses.deviceMessage : null;
        timeout = (new Date(data.endDate).getTime() - new Date().getTime());
      }
      else {
        data.button = {text: this.locale.liveclasses.enrolledButton, disabled: false, class: 'button-tertiary', loading: false}
        data.note = null;
        data.deviceMessageText = null;
        timeout = (new Date(data.startDate).getTime() - new Date().getTime());
      }
      timeout = Math.abs(Math.round(timeout));
      setTimeout(() => {
        if(end) this.realTimeChecker(data);
        else return null;
      }, timeout);
    }

    onClickClass(obj) {
      if(obj.button.text === this.locale.liveclasses.enrollButton) {
        this.enrollClass(obj);
      }
      else if(obj.button.text === this.locale.liveclasses.joinNow) {
        this.openJoinPopup(obj);
      }
      else console.log('click', obj)
    }

    enrollClass(classObj) {
      this.lesson = classObj.title;
      classObj.button.loading = true;
      this.business.enrollLiveClass(classObj.id).subscribe(res=>{
        console.log('enroll live class------>', res[0].message)
        classObj.button.loading = false;
        this.notiPoup = true;
        this.loadData();
        document.getElementById('header_nav').style.zIndex = "unset";
        setTimeout(()=>{
          this.notiPoup = false;
          document.getElementById('header_nav').style.zIndex = "2";
          document.getElementById('dashboard-home').style.zIndex = null;
          document.getElementById('dashboard-home').style.maxHeight = null;
          document.getElementById('dashboard-home').style.overflow = null;
        },3500)
      },err=>{
        console.log('enroll error-----',err)
        if(err.code == "LIVECLASS.enrollLiveClassLimitReached"){
          this.errorPoup = true;
        }else{
          if(err.error.message){
            this.toastr.error(err.error.message);
          }else{
            this.toastr.error("something went wrong");
          }
        }
        classObj.button.loading = false;
        // this.errorPoup = true;
      })
    }

    openJoinPopup(classObj) {
      console.log('>>>open join popup',classObj)
      this.joinPopup.enable = true;
      this.joinPopup.title = classObj.zoomLink == '' ? this.locale.liveclasses.joining + '...' : this.locale.liveclasses.joinLesson;
      this.joinPopup.classname = classObj.title;
      this.joinPopup.teachername = classObj.teacherName;
      this.joinPopup.username = this.userData.username;
      this.joinPopup.zoomLink = classObj.zoomLink;
      this.joinPopup.zoomRoomID = classObj.zoomRoomID;
      this.joinPopup.zoomRoomPasscode = classObj.zoomRoomPasscode;
    }

    hideJoinPopup(e) {
      console.log("e>>",e)
      // if(e=='button_click') this.joinClass();
      // else this.joinPopup.enable = false;
      this.joinPopup.enable = false;
      console.log("this.joinPopup.enable >>",this.joinPopup.enable)
    }

    hideNoti(){
      this.notiPoup = false;
      document.getElementById('header_nav').style.zIndex = "2";
    }

    hideErrorPopup(){
      this.errorPoup = false;
    }

    checkLoginData(){
        // var alreadyLoggedin = this._authService.isLoginUser();
        // var url = this._sharedService.getPreviousUrl();
        // url = url? url: '/';
        // if(!alreadyLoggedin) this.router.navigate([url]);

        //go to login form when no auth user visit to root/liveclasses
        var alreadyLoggedin = this._authService.isLoginUser();
        if(!alreadyLoggedin) this._sharedService.setEnableLogin({enable: true, url: this.router.url});
    }

    onClickTeacherProfile(data){
      console.log("onClickTeacherProfile",data);
      this.router.navigate(['/teacher/' + data.teacherId]);
    }

    ngOnDestroy() {
      this.userInfoSubscription.unsubscribe();
    }
}
