<div class="live-class-wrap bg-s10">
    <div class="center-wrap">
        <!-- <app-text>{{liveClassList[0].endDate | date: 'shortTime' }}</app-text> -->
        <app-text class="h2-strong live-title text-secondary700">{{locale?.liveclasses.liveClassTitle}}</app-text>
        <app-teacher-profile-list *ngIf="isAcademyPermitted" [profileList]="teacherList" [locale]="locale" [isLoading]="isProfileLoading" (onClickProfile)="onClickTeacherProfile($event)"></app-teacher-profile-list>
        <div *ngIf="isAcademyPermitted" class="class-list">
            <app-class-card *ngFor="let item of liveClassList" (clickenroll)="onClickClass($event)" [liveClassList]="item" [locale]="locale" [usertype]="userData.usertype"></app-class-card>

            <app-text *ngIf="!isloading && liveClassList?.length==0" class="body-l text-c200 ml-2 mt-2">{{locale?.liveclasses.noData}}</app-text>
            <div class="col-md-4">
              <app-loader *ngIf="isloading" [spinColor]="'#FBF7EC'" [spinTopColor]="'#FFD918'" [height]="'80px'" [width]="'80%'"></app-loader>
              <app-loader *ngIf="isloading" [spinColor]="'#FBF7EC'" [spinTopColor]="'#FFD918'" [height]="'80px'" [width]="'80%'"></app-loader>
            </div>
            <div class="col-md-4">
              <app-loader *ngIf="isloading" [spinColor]="'#FBF7EC'" [spinTopColor]="'#FFD918'" [height]="'80px'" [width]="'80%'"></app-loader>
              <app-loader *ngIf="isloading" [spinColor]="'#FBF7EC'" [spinTopColor]="'#FFD918'" [height]="'80px'" [width]="'80%'"></app-loader>
            </div>
            <div class="col-md-4">
              <app-loader *ngIf="isloading" [spinColor]="'#FBF7EC'" [spinTopColor]="'#FFD918'" [height]="'80px'" [width]="'80%'"></app-loader>
              <app-loader *ngIf="isloading" [spinColor]="'#FBF7EC'" [spinTopColor]="'#FFD918'" [height]="'80px'" [width]="'80%'"></app-loader>
            </div>
        </div>
        <div *ngIf="!isAcademyPermitted" class="row">
          <app-text class="body-l text-c200 ml-2 mt-2">{{locale?.liveclasses.needPermission}}</app-text>
        </div>
    </div>
    <app-custom-notification *ngIf="notiPoup" [locale]="locale" [user]="userData" [lesson]="lesson" (onClickDismiss)="hideNoti()"></app-custom-notification>
    <app-custom-popup *ngIf="errorPoup" [locale]="locale" [lesson]="lesson" [isAndroidApp]="isAndroidApp" (onClickClose)="hideErrorPopup()"></app-custom-popup>
    <app-custom-popup *ngIf="joinPopup.enable" [locale]="locale" [isAndroidApp]="isAndroidApp" (onClickClose)="hideJoinPopup($event)" [title]="joinPopup.title" [joinInstruction]="joinPopup"></app-custom-popup>
</div>

