export const environment = {
  production: false,
  envName: 'staging',
  apiurl: 'https://channel-api-staging.stemwerkz.org/',
  apikey: 'bc77612ecd1a2e7f6ace383b4cee7848',
  stemgameapiurl: 'https://sv-api-staging.stemwerkz.org/',
  secretkey: 'bc776',
  valleyUrl: 'https://sv-staging.stemwerkz.org/',
  dashboardUrl: 'https://student-staging.stemwerkz.org/',
  questsUrl: 'https://quest-staging.stemwerkz.org',
  liveClassApiurl: 'https://live-api-staging.stemwerkz.org/',
  nodmaUrl: 'https://nodma-staging.pagewerkz.com/',
  membershipUrl: 'https://account-staging.stemwerkz.org/',
  stemUrl: 'https://www.stemwerkz.org',
  valleyPopupVideoUrl: 'https://du4diohu7suph.cloudfront.net/stemvalley-demo/steamValleyTeaser1.mp4',
  openAppJSUrl: 'https://pagewerkz-authoring.s3.amazonaws.com/stemwerkz/open-in-app-js/staging/openInApp.js',
  stemwerkzStartUrl: 'https://start-staging.stemwerkz.org/',
  disabledAcademy: false,
  // logo: 'chinese',
  logo: 'english',
  showStartView: false // true or false
};
